import React, { Suspense, lazy,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Layout/Navbar';
import Footer from './components/Layout/Footer';
import Loading from './components/Layout/Loading';
import NotFound from './components/Layout/NotFound'; // Your 404 component

// import FeedbackForm from "./components/Layout/FeedbackForm";

// Lazy load the pages
const Home = lazy(() => import('./components/Home/Home'));
const Login = lazy(() => import('./components/Auth/Login'));
const Register = lazy(() => import('./components/Auth/Register'));
const CourseList = lazy(() => import('./components/Courses/CourseList'));
const CreateCourse = lazy(() => import('./components/Courses/CreateCourse'));
const CourseDetail = lazy(() => import('./components/Courses/CourseDetail'));
const ForgotPassword = lazy(() => import('./components/Auth/ForgotPassword'));
const ResetPasswordWithCode = lazy(() => import('./components/Auth/ResetPasswordWithCode'));
const MyLearnings = lazy(() => import('./components/User/MyLearnings'));
const MyTeachings = lazy(() => import('./components/User/MyTeachings'));
const EditCourse = lazy(() => import('./components/Courses/EditCourse'));
const CourseStructurePage = lazy(() => import('./components/Courses/CourseStructurePage'));
const EditOrderPage = lazy(() => import('./components/Courses/EditOrderPage'));
const LiveStream = lazy(() => import('./components/LiveStream/LiveStream'));
const Pricing = lazy(() => import('./components/Pricing/Pricing'));
const MyProfile = lazy(() => import('./components/User/MyProfile'));
const SearchResults = lazy(() => import('./components/Search/SearchResults'));
const InstructorTerms= lazy(()=>import('./components/Courses/InstructorTerms'))
const BankDetailsPage = lazy(() => import('./components/User/BankDetailsPage'));
const PasswordResetSuccess =lazy(()=>import ('./components/Auth/PasswordResetSuccess'));
const RegisterSuccess =lazy(()=>import ('./components/Auth/RegisterSuccess'));
const RegisterReferralSuccess =lazy(()=>import ('./components/Auth/RegisterReferralSuccess'));

const Referral = lazy(() => import('./components/User/ReferralDiscount'));
const AccountSettings = lazy(() => import('./components/User/AccountSettings'));

function App() {
  useEffect(() => {
    const checkTokenExpiry = () => {
      const tokenExpiry = localStorage.getItem('tokenExpiry');
      if (tokenExpiry && Date.now() > parseInt(tokenExpiry, 10)) {
        // Remove expired token
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiry');
        // Refresh the page
        
        window.location.reload();
      }
    };

    // Check token expiry on an interval (e.g., every 5 seconds)
    const interval = setInterval(checkTokenExpiry, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  
  return (
    <Router>
      <Navbar />
      {/* Suspense will display the Loading component until content is loaded */}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/courses" element={<CourseList />} />
          <Route path="/create-course" element={<CreateCourse />} />
          <Route path="/courses/:courseId" element={<CourseDetail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password-with-code" element={<ResetPasswordWithCode />} />
          <Route path="/my-learnings" element={<MyLearnings />} />
          <Route path="/my-teachings" element={<MyTeachings />} />
          <Route path="/courses/edit/:courseId" element={<EditCourse />} />
          <Route path="/courses/:courseId/manage-videos" element={<CourseStructurePage />} />
          <Route path="/courses/:courseId/edit-order" element={<EditOrderPage />} />
          <Route path="/live/:roomId/:role" element={<LiveStream />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/instructor-terms" element={<InstructorTerms />} />
          <Route path="/payout-settings" element={<BankDetailsPage />} />
          <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
          <Route path="/register-success" element={<RegisterSuccess />} />
          <Route path="/register-referral-success" element={<RegisterReferralSuccess />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/referral-discount" element={<Referral />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      {/* <FeedbackForm /> */}
      <Footer />
    </Router>
  );
}

export default App;
